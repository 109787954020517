"use client";

import {
  Checkbox,
  VStack,
  Box,
  Divider,
  Text,
} from "@/components/ChakraUiManager";
import "./TutorialsFilter.scss";
import { useSearchParams } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { useState, useEffect } from "react";
import { getTutorialFilterGroups } from "@/prismic-api/tutorial-service";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

export default function TutorialsFilter(props: any) {
  const prismicClient = useSelector(getAppPrismicClient);

  const [filters, setFilters] = useState<any>();
  //fetch filters
  const getFilters = async () => {
    const filtersData = await getTutorialFilterGroups(prismicClient);
    setFilters(filtersData);
  };

  useEffect(() => {
    getFilters();
  }, []);
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams?.toString());

  const currentFilters: string[] = [];
  if (params.has("tags")) {
    const tagString = decodeURIComponent(params.get("tags") || "");
    currentFilters.push(...tagString.split(","));
  }

  const handleCheckboxChange = (label: string) => () => {
    let tag = label;
    if (currentFilters.includes(tag)) {
      const index = currentFilters.indexOf(tag);
      if (index > -1) {
        currentFilters.splice(index, 1);
      }
    } else {
      currentFilters.push(tag);
    }
    const params = new URLSearchParams(searchParams?.toString());
    params.set("tags", encodeURIComponent(currentFilters.join(",")));
    if (currentFilters.length < 1) {
      params.delete("tags");
    }
    let url = new URL(window.location.href);
    url.searchParams.set("tags", encodeURIComponent(currentFilters.join(",")));
    if (currentFilters.length < 1) {
      url.searchParams.delete("tags");
    }
    url.searchParams.delete("docId");
    url.pathname = ROUTE_BASE_URL.TUTORIALS;
    window.location.href = url.href;
  };

  const clearFilters = () => {
    const params = new URLSearchParams(searchParams?.toString());
    params.delete("tags");
    params.delete("docId");
    window.location.search = params.toString();
  };

  return (
    <Box className="tutorials-filter">
      {filters && (
        <VStack
          className="full-group"
          gap={0}
          alignItems="flex-start"
          divider={<Divider className="divider" />}
        >
          <Box className="header">
            <h5>Filters</h5>
          </Box>
          {filters.groups.map((group: any, key: number) => (
            <Box key={key}>
              <Text className="group-name">{group.name}</Text>
              <VStack alignItems="flex-start">
                {group.tags.map((tag: string, tagKey: number) => (
                  <Box key={tagKey} mt="4" className="label">
                    <Checkbox
                      isChecked={currentFilters.includes(tag) ? true : false}
                      onChange={handleCheckboxChange(tag)}
                    >
                      {tag}
                    </Checkbox>
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
          <Box alignItems="flex-start">
            <button onClick={clearFilters} className="clear">
              Clear Filters
            </button>
          </Box>
        </VStack>
      )}
    </Box>
  );
}
