"use client";

import { AppChildrenNode } from "@/models/global-models";
import DocumentCenterSideMenu from "@/components/documentcenter/DocCenterSideMenu/DocumentCenterSideMenu";
import { NavigationDataProvider } from "./providers";
import ApiDocLayout from "@/components/api-doc-layout/ApiDocLayout";

export default function DocumentCenterLandingPage({
  children,
}: AppChildrenNode) {
  return (
    <ApiDocLayout
      provider={NavigationDataProvider}
      sideMenu={DocumentCenterSideMenu}
    >
      {children}
    </ApiDocLayout>
  );
}
