import { AppChildrenNode } from "@/models/global-models";
import { Box, HStack, Show } from "@chakra-ui/react";
import CopyrightFooter from "./copyright-footer/CopyrightFooter";
import "./ApiLayout.scss";

type Props = {
  provider: ({ children }: any) => React.JSX.Element;
  sideMenu: () => React.JSX.Element;
  children: AppChildrenNode["children"];
};

const ApiDocLayout = (props: Props) => {
  const {
    provider: NavigationDataProvider,
    sideMenu: SideMenu,
    children,
  } = props;
  return (
    <HStack className="api-doc-layout-container">
      <NavigationDataProvider>
        <Show above="md">
          <SideMenu />
        </Show>
        <Box className="main-container">
          <Box className="content">{children}</Box>
          <CopyrightFooter />
        </Box>
      </NavigationDataProvider>
    </HStack>
  );
};
export default ApiDocLayout;
