"use client";

import { Box, Text } from "@chakra-ui/react";
import css from "./CopyrightFooter.module.scss";

const CopyrightFooter = () => {
  return (
    <Box className={css["footer"]}>
      <Text className={css["text"]}>
        Copyright © 2024 Finxact a Fiserv Company. All rights reserved.
      </Text>
    </Box>
  );
};

export default CopyrightFooter;
