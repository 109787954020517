"use client";

import { _404 } from "@/app/not-found";
import { UserContext, useUserAuthenticated } from "@/app/providers";
import { DocumentDetailPage } from "@/components";
import { getDocCenterDocument } from "@/prismic-api/document-service";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import { getAppPrismicClient } from "@/store";
import { checkIfRestricted } from "@/utils";
import { Box, Button } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DocDetails = (props: { slug: { docType: string; docId: string } }) => {
  const [restricted, setRestricted] = useState(false);
  const isAuthenticated = useUserAuthenticated();
  const { user } = useContext(UserContext);
  const { docType, docId } = props.slug;
  const prismicClient = useSelector(getAppPrismicClient);
  const [data, setData] = useState<
    Awaited<ReturnType<typeof getDocCenterDocument>> | null | "404"
  >(null);

  // Fetch document data from prismic
  useEffect(() => {
    (async () => {
      try {
        const res = await getDocCenterDocument(prismicClient, docType, docId);
        setData(res);
      } catch (err) {
        setData("404");
      }
    })();
  }, [docId, docType]);

  useEffect(() => {
    if (
      typeof data === "object" &&
      !isAuthenticated &&
      checkIfRestricted(data?.tags ?? [])
    ) {
      setRestricted(true);
    } else {
      setRestricted(false);
    }
  }, [isAuthenticated, data]);

  if (data === "404") {
    return _404;
  }

  return (
    <>
      {!restricted && data && <DocumentDetailPage home={data} />}
      {restricted && user.loading === false && (
        <Box pt={32} className="document-page restricted" textAlign="left">
          <h4>404 Not Found</h4>

          <div className="restricted-alert">
            Please log in to view this content.
          </div>

          <Button
            className="app-btn-inverse-secondary"
            onClick={() => {
              //sending the query param in encoded base64 format
              window.location.href = `/console/login?${QUERY_PARAM_KEY.LOGIN_SUCCESS_REDIRECT_PAGE}=${encodeURIComponent(`${window.location.pathname}/${docType}/${docId}`)}`;
            }}
          >
            Log in
          </Button>
        </Box>
      )}
    </>
  );
};

export default DocDetails;
