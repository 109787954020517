"use client";

import { AppChildrenNode } from "@/models/global-models";
import APILibrarySideMenu from "@/components/api-library/side-menu/APILibrarySideMenu";
import { NavigationDataProvider } from "./providers";
import ApiDocLayout from "@/components/api-doc-layout/ApiDocLayout";

export default function APILibraryLandingPage({ children }: AppChildrenNode) {
  return (
    <ApiDocLayout
      provider={NavigationDataProvider}
      sideMenu={APILibrarySideMenu}
    >
      {children}
    </ApiDocLayout>
  );
}
