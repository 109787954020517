"use client";
import { usePathname } from "next/navigation";
import { DocNotFound } from "./docs/DocNotFound";
import { ApiNotFound } from "./apis/ApiNotFound";
import { Box } from "@chakra-ui/react";

export const _404 = (
  <Box
    sx={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 20
    }}
  >
    404 page not found
  </Box>
);

export default function NotFound() {
  const pathName = usePathname();
  const fastModule = pathName?.split("/");
  if (fastModule) {
    switch (fastModule[1]) {
      case "docs":
        return <DocNotFound />;
      case "apis":
        return <ApiNotFound />;
      default:
        return _404;
    }
  }
}
